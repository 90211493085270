import {
  CountMarketFreightsQuery,
  CountMarketFreightsQueryHandler,
  CountResponse,
  FindMarketFreightQuery,
  FindMarketFreightQueryHandler,
  FreightMarketResponse,
  SearchMarketFreightsQuery,
  SearchMarketFreightsQueryHandler,
} from '@okcargo/query-processor';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { BaseQueryRepository } from 'src/app/common/infrastructure/query.repository';

@Injectable({
  providedIn: 'root',
})
export class MarketQueryService {
  private countMarketFreightsQueryHandler: CountMarketFreightsQueryHandler;
  private searchMarketFreightsQueryHandler: SearchMarketFreightsQueryHandler;
  private findMarketFreightQueryHandler : FindMarketFreightQueryHandler;

  constructor(private queryRepository: BaseQueryRepository) {
    this.countMarketFreightsQueryHandler = new CountMarketFreightsQueryHandler(queryRepository);
    this.searchMarketFreightsQueryHandler = new SearchMarketFreightsQueryHandler(queryRepository);
    this.findMarketFreightQueryHandler = new FindMarketFreightQueryHandler(queryRepository);
  }

  countMarketFreights(
    query: CountMarketFreightsQuery
  ): Promise<CountResponse> {
    return this.countMarketFreightsQueryHandler.handle(query);
  }

  searchMarketFreights(
    query: SearchMarketFreightsQuery
  ): Promise<FreightMarketResponse[]> {
    return this.searchMarketFreightsQueryHandler.handle(query);
  }

  findFreightFromMarket(
    query: FindMarketFreightQuery
  ): Observable<FreightMarketResponse> {
    return from(this.findMarketFreightQueryHandler.handle(query));
  }
}
