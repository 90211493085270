interface AbstractStop {
  id: UUID;
  position: number;
  type: EStopType; // enum
  since: Date;
  until: Date;
  status: EStopStatus; // NEW enum
  address: IAddress;
}

interface ICarrier {
  id: number;
  name: string;
  taxId: string;
  phone: string;
}

// from DTO FreightBasicResponse
export interface IMarketFreight {
  id: UUID;
  journeyId: UUID;
  carrier: ICarrier;
  reference: string;
  vehicleTypes: EFreightVehicleType[];
  loadingTypes: EFreightLoadingType[];
  deliveryNoteInPaper: boolean;
  instructions: string;
  numberOfStops: number;
  status: EFreightStatus;
  stops: IStopBasic[];
  price: number;
  nature: string;
  weight: number;
  packages: number;
  title: string;
  assignationId: UUID;
  isAssigned: boolean;
  // shipperCarrierAssignationId: UUID;
  // carrierCollaboratorAssignationId: UUID;
}

export function getEmptyDefaultMarketFreight(): IMarketFreight {
  return {
    id: '',
    journeyId: '',
    carrier: {} as ICarrier,
    reference: '',
    vehicleTypes: [],
    loadingTypes: [],
    deliveryNoteInPaper: false,
    instructions: '',
    numberOfStops: 0,
    status: EFreightStatus.DEFAULT,
    stops: [],
    price: 0,
    nature: '',
    weight: 0,
    packages: 0,
    title: '',
    assignationId: '',
    isAssigned: false,
  }
}

export interface IStopBasic extends AbstractStop {
  // reference: string, // not neccesary yet
}

export interface IAddress {
  company?: string; // New, display in address component. Get this value in mapper.
  street: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  coords?: ICoords; // Map from pointPayload. // Note: not needed coords in card (or pseudo-table) component
}

export interface ICoords {
  latitude: number;
  longitude: number;
}

/*************** */

/*************** */

// export declare type UUID = string;
export type UUID = string;

// export declare enum FreightStopType {
export enum EStopType {
  LOAD = 'LOAD',
  UNLOAD = 'UNLOAD',
  UNLOAD_LOAD = 'UNLOAD_LOAD',
  SWAP = 'SWAP',
}

export enum EStopStatus {
  UNSTARTED = 'UNSTARTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

// export declare enum VehicleType {
// TODO review translations, not all are included, not all included in the translations are necessary
export enum EVehicleType {
  TRACTOR = 'TRACTOR',
  TRAILER = 'TRAILER',
  TRUCK = 'TRUCK',
  VAN = 'VAN',
  SMALL_TRAILER = 'SMALL_TRAILER',
  MEGA_TRAILER = 'MEGA_TRAILER',
  DUO_TRAILER = 'DUO_TRAILER',
  GAS = 'GAS',
  GAS_CARRIER = 'GAS_CARRIER',
  BIODIESEL = 'BIODIESEL',
  BIODIESEL_CARRIER = 'BIODIESEL_CARRIER',
  TRAIN = 'TRAIN',
  TRAIN_CARRIER = 'TRAIN_CARRIER',
  EXCLUDED_CARRIER = 'EXCLUDED_CARRIER',
}


// new, only drivers app
export enum EFreightStatus {
  DEFAULT = "DEFAULT", // new, default value when object is created

  SENT = 'SENT',

  PENDING = 'PENDING',

  ASSIGNED = 'ASSIGNED',
  ON_ROUTE = 'ON_ROUTE',
  FINISHED = 'FINISHED',

  DELETED = 'DELETED', // can't view
  CANCELLED = 'CANCELLED', // can view, can't modify

  ERROR = 'ERROR' // new, is empty or not valid
}


export declare enum EFreightVehicleType {
  CANVAS = "CANVAS",
  MOBILE_CANVAS = "MOBILE_CANVAS",
  VAN = "VAN",
  BOX = "BOX",
  RIGID_TRUCK = "RIGID_TRUCK",
  RIGID_CANVAS = "RIGID_CANVAS",
  REFRIGERATED = "REFRIGERATED",
  RIGID_REFRIGERATED = "RIGID_REFRIGERATED",
  BIREFRIGERATED = "BIREFRIGERATED",
  TRIREFRIGERATED = "TRIREFRIGERATED",
  BULK = "BULK",
  DUMP = "DUMP",
  TANKER = "TANKER",
  REFRIGERATED_TANKER = "REFRIGERATED_TANKER",
  CRANE = "CRANE",
  OTHER = "OTHER"
}

export declare enum EFreightLoadingType {
  REAR = "REAR",
  SIDE = "SIDE",
  TOP = "TOP"
}


/*** */

// export declare enum FreightStatus {
  export enum EFreightStatusOLD {
    // OFFER_REQUESTED = "OFFER_REQUESTED",
    // OFFER_SENT = "OFFER_SENT",
    // OFFER_REJECTED = "OFFER_REJECTED",
    // PENDING = "PENDING",
    ASSIGNED = 'ASSIGNED', // asignado, no iniciado
    CANCELLED_BY_CARRIER = 'CANCELLED_BY_CARRIER', // puedo ver, pero no puede interactuar
    CANCELLED_BY_SHIPPER = 'CANCELLED_BY_SHIPPER', // idem
    ACCEPTED = 'ACCEPTED', // asignado y aceptado
    REJECTED = 'REJECTED', // asignado y rechazado
    // STARTED = "STARTED", // no se usa, pero es ir hacia la carga
    ARRIVED = 'ARRIVED', // he llegado a una parada, he marcado que he llegado pero no he marcado que he salido
    // WORKING_START = "WORKING_START",
    // WORKING_END = "WORKING_END",
    ON_ROUTE = 'ON_ROUTE', // he marcado la salida de una parada y voy a la siguiente
    FINISHED = 'FINISHED', // acabado todas las paradas y marcado la finalización
    FINISHED_BY_CARRIER = 'FINISHED_BY_CARRIER', // no se ha usado la app, lo marca el cargador
    FINISHED_BY_SHIPPER = 'FINISHED_BY_SHIPPER', // no se ha usado la app, lo marca tráfico
    FINISHED_WITH_ISSUE = 'FINISHED_WITH_ISSUE', // hay una incidencia, no se puede facturar
    FINISHED_WITH_ISSUE_SOLVED = 'FINISHED_WITH_ISSUE_SOLVED', // siguiente paso del estado anterior
    // NO_SHOW_BY_CARRIER = "NO_SHOW_BY_CARRIER",
    // NO_SHOW_BY_SHIPPER = "NO_SHOW_BY_SHIPPER",
    DELETED = 'DELETED',
  }
