import { Injectable, signal } from '@angular/core';
import {
  CriteriaPayload,
  FindMarketFreightQuery,
  FreightFieldNames,
  FreightStatus,
  OrderPayload,
  OrderType,
  SearchMarketFreightsQuery
} from '@okcargo/query-processor';
import { BehaviorSubject, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import {
  IStopBasic,
  EFreightStatus,
  IMarketFreight,
  IAddress,
  getEmptyDefaultMarketFreight,
  EFreightVehicleType,
  EFreightLoadingType
} from './../domain/market.model';
import { MarketQueryService } from '../infrastructure/market-query.service';
import { AcceptFreightAssignationCommand, AcceptFreightAssignationFromMarketCommand } from '@okcargo/command-processor';
import { MarketCommandService } from '../infrastructure/market-command.service';
// import { MarketCommandService } from '../infrastructure/market-command.service';

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  page: number = 0;
  size: number = 200;

  private _freightsInMarket$ = new BehaviorSubject<IMarketFreight[]>([]);

  private _refreshMarketDetail$ = new BehaviorSubject<void>(undefined);

  public freightsInMarketLoading = signal(true);

  constructor(
    private marketQueryService: MarketQueryService,
    private marketCommandService: MarketCommandService
  ) {
    // this.getFreightsInMarket();
  }

  get freightsInMarket$() {
    return this._freightsInMarket$;
  }

  get refreshMarketDetail$(): Observable<void> {
    return this._refreshMarketDetail$.asObservable();
  }

  public refreshFreightsInMarket(): void {
    this.getFreightsInMarket();
  }

  public refreshMarketDetail(): void {
    this._refreshMarketDetail$.next();
  }

  private getFreightsInMarket(): void {
    const query = {
      criteriaPayload: {
        order: [
          {
            field: FreightFieldNames.STARTING_AT,
            type: OrderType.ASC,
          } as OrderPayload,
        ],
        filters: [],
        pageIndex: this.page,
        pageSize: this.size,
      } as unknown as CriteriaPayload,
    } as SearchMarketFreightsQuery;

    from(this.marketQueryService.searchMarketFreights(query))
      .pipe(
        map((freights) =>
          freights.map((marketFreightResponse): IMarketFreight => {
            const freight: IMarketFreight = {
              id: marketFreightResponse.id,
              journeyId: marketFreightResponse.journeyId,
              numberOfStops: marketFreightResponse.numberOfStops,
              reference: marketFreightResponse.carrierFreightM2mReference,
              price: marketFreightResponse.price,
              stops: this.transformToIStop(marketFreightResponse.stops),
              status: this.transformToEFreightStatus(marketFreightResponse.status), // calculate this
              carrier: {
                id: marketFreightResponse.carrierId,
                name: marketFreightResponse.carrierName,
                phone: marketFreightResponse.carrierPhone,
                taxId: marketFreightResponse.carrierTaxId
              },
              deliveryNoteInPaper: false,
              instructions: marketFreightResponse.instructions,
              loadingTypes: marketFreightResponse.loadingTypes.map((vehicle) => vehicle as unknown as EFreightLoadingType), // marketFreightResponse.loadingTypes,
              vehicleTypes: marketFreightResponse.vehicleTypes.map((vehicle) => vehicle as unknown as EFreightVehicleType), // marketFreightResponse.vehicleTypes,
              nature: marketFreightResponse.nature,
              packages: marketFreightResponse.packages,
              weight: marketFreightResponse.weight,
              title: 'Carga ' + marketFreightResponse.carrierFreightM2mReference,
              assignationId: marketFreightResponse.carrierCollaboratorAssignationId,
              isAssigned: false
            };
            return freight; // this.transformToIMarketFreight(marketFreightResponse, freight);
          })
        )
      )
      .subscribe((transformedFreights) => {
        this._freightsInMarket$.next(transformedFreights);
        this.freightsInMarketLoading.set(false);
      });
  }

  getMarketFreightDetail$(id: string): Observable<IMarketFreight> {
    const query = {
      id: id,
    } as FindMarketFreightQuery;

    return from(this.marketQueryService.findFreightFromMarket(query)).pipe(
      switchMap((marketFreightDetailedResponse): Observable<IMarketFreight> => {

        if (!marketFreightDetailedResponse || typeof marketFreightDetailedResponse === 'string') {
          // console.warn('Freight vacío');
          const freight = getEmptyDefaultMarketFreight();
          freight.status = EFreightStatus.ERROR;
          return of(freight);
        }

        const transformedFreight: IMarketFreight = {
          id: marketFreightDetailedResponse.id,
          numberOfStops: marketFreightDetailedResponse.numberOfStops,
          reference: marketFreightDetailedResponse.carrierFreightM2mReference,
          price: marketFreightDetailedResponse.price,
          stops: this.transformToIStop(marketFreightDetailedResponse.stops),
          status: this.transformToEFreightStatus(marketFreightDetailedResponse.status), // calculate this
          carrier: {
            id: marketFreightDetailedResponse.carrierId,
            name: marketFreightDetailedResponse.carrierName,
            phone: marketFreightDetailedResponse.carrierPhone,
            taxId: marketFreightDetailedResponse.carrierTaxId
          },
          deliveryNoteInPaper: false,
          instructions: marketFreightDetailedResponse.instructions,
          loadingTypes: marketFreightDetailedResponse.loadingTypes.map((vehicle) => vehicle as unknown as EFreightLoadingType), // marketFreightResponse.loadingTypes,
          nature: marketFreightDetailedResponse.nature,
          packages: marketFreightDetailedResponse.packages,
          vehicleTypes: marketFreightDetailedResponse.vehicleTypes.map((vehicle) => vehicle as unknown as EFreightVehicleType), // marketFreightResponse.vehicleTypes,
          weight: marketFreightDetailedResponse.weight,
          title: marketFreightDetailedResponse.carrierFreightM2mReference,
          assignationId: marketFreightDetailedResponse.carrierCollaboratorAssignationId,
          isAssigned: marketFreightDetailedResponse.isAssigned,
          journeyId: marketFreightDetailedResponse.journeyId,
        };

        //return freight; // this.transformToIMarketFreight(marketFreightResponse, freight);
        return of(transformedFreight);
      })
    );
  }

  async acceptFreightAssignationFromMarket(id: string, assignationId: string) {
    this.marketCommandService
      .acceptFreightAssignationFromMarket({
        assignationId: assignationId,
        parentAssignationId: null as unknown as string,
        freightId: id
      } as AcceptFreightAssignationFromMarketCommand)
      .then(
        () => {},
        async (error: any) => {
          // TODO implement this
          console.warn(error);
        }
      );
  }

  private transformToIStop(stops: any[]): IStopBasic[] {
    return stops.map((stop: any) => {
      return {
        id: stop.id,
        address: {
          city: stop.city,
          postalCode: stop.postalCode,
          state: stop.state,
          country: stop.country
        } as IAddress,
        position: +stop.position, // number
        since: new Date(stop.since),
        until: new Date(stop.until),
        status: stop.status,
        type: stop.type
      } as IStopBasic
    });
  }

  /**** */

  /*private transformToIMarketFreight(
    marketFreightResponse: FreightMarketResponse,
    freight: IMarketFreight
  ): IMarketFreight {
    freight = {
      ...freight,
      id: marketFreightResponse.id,
      numberOfStops: marketFreightResponse.numberOfStops,
      reference: marketFreightResponse.carrierFreightM2mReference,
      price: marketFreightResponse.finalPrice
        ? marketFreightResponse.finalPrice
        : undefined,
    };

    marketFreightResponse.stops.forEach((stopResponse) => {
      const stop: IStopBasic = {
        id: stopResponse.id,
        position: stopResponse.position,
        type: stopResponse.stopType as unknown as EStopType,
        status: stopResponse.stopStatus as unknown as EStopStatus,
        since: new Date(stopResponse.since),
        until: new Date(stopResponse.until),
        address: {
          company: stopResponse.companyName,
          street: stopResponse.addressPayload.street,
          city: stopResponse.addressPayload.city,
          postalCode: stopResponse.addressPayload.postalCode,
          state: stopResponse.addressPayload.state,
          country: stopResponse.addressPayload.country,
        },
        // reference: stopResponse.reference, // not necessary yet
      };

      freight.stops.push(stop);
    });

    if (
      marketFreightResponse.nextStopId !== undefined &&
      freight.stops.length > 0
    ) {
      freight.nextStopId = freightBasicResponse.nextStopId;
      freight.nextStopType = freight.stops.find(
        (stop) => stop.id === freight.nextStopId
      )!.type;
      freight.nextStopStatus = freight.stops.find(
        (stop) => stop.id === freight.nextStopId
      )!.status;
    }

    if (freightBasicResponse.journeys.length > 0) {
      freightBasicResponse.journeys.forEach((journeyResponse) => {
        if (journeyResponse.driver !== undefined) {
          const driver: IFreightDriver = {
            id: journeyResponse.driver.id,
            name: journeyResponse.driver.driverName,
          };
          freight.drivers?.push(driver);
        }
        if (journeyResponse.vehicles !== undefined) {
          journeyResponse.vehicles.forEach((vehicleResponse) => {
            const vehicle: IFreightVehicles = {
              id: vehicleResponse.id,
              plate: vehicleResponse.plateNumber,
              partyId: vehicleResponse.companyId,
              type: vehicleResponse.type as unknown as EVehicleType,
            };
            freight.vehicles?.push(vehicle);
          });
        }
      });
    }

    if (freightBasicResponse.status !== undefined)
      freight.status = this.transformToEFreightStatus(
        freightBasicResponse.status
      );

    if (
      (freightBasicResponse.numberOfDeliveryNotes === undefined ||
        freightBasicResponse.numberOfDeliveryNotes === 0) &&
      freight.status !== EFreightStatus.CANCELLED
    ) {
      freight.hasEnoughDocs = false;
    }

    if (freightBasicResponse.deliveryNoteInPaperNeeded !== undefined) {
      freight.deliveryNoteInPaperNeeded =
        freightBasicResponse.deliveryNoteInPaperNeeded;
      if (freightBasicResponse.deliveryNoteInPaperNeeded !== undefined)
        freight.deliveryNoteInPaperReceived =
          freightBasicResponse.deliveryNoteInPaperReceived;
    }

    return freight;
  }

  private transformToIFreightDetail(
    freightDetailedResponse: FreightDetailedResponse,
    freight: IFreightDetail
  ): IFreightDetail {
    freightDetailedResponse.freightStopResponses.forEach((stopResponse) => {
      let stop: IStopDetail = {
        id: stopResponse.id,
        freightId: stopResponse.freightId,
        position: stopResponse.position,
        type: stopResponse.type as unknown as EStopType,
        status: EStopStatus.UNSTARTED, // calculate this with arrivedAt and departedAt
        since: new Date(stopResponse.since),
        until: new Date(stopResponse.until),
        address: {
          company: stopResponse.companyPayload.name,
          street: stopResponse.addressPayload.street,
          city: stopResponse.addressPayload.city,
          postalCode: stopResponse.addressPayload.postalCode,
          state: stopResponse.addressPayload.state,
          country: stopResponse.addressPayload.country,
          coords: stopResponse.addressPayload.pointPayload,
        },
      };
      if (stopResponse.goodsDescription !== undefined)
        stop.goodsDescription = stopResponse.goodsDescription;
      if (stopResponse.reference !== undefined)
        stop.reference = stopResponse.reference;
      if (stopResponse.arrivedAt !== undefined)
        stop.arrivedAt = new Date(stopResponse.arrivedAt);
      if (stopResponse.departedAt !== undefined)
        stop.departedAt = new Date(stopResponse.departedAt);
      if (stopResponse.netWeight !== undefined)
        stop.netWeight = stopResponse.netWeight;
      if (stopResponse.packages !== undefined)
        stop.packages = stopResponse.packages;
      if (stopResponse.unloadedNetWeight !== undefined)
        stop.unloadedNetWeight = stopResponse.unloadedNetWeight;
      if (stopResponse.unloadedPackages !== undefined)
        stop.unloadedPackages = stopResponse.unloadedPackages;
      if (stopResponse.arrivalDriverAppPoint !== undefined)
        stop.arrivalDriverAppPoint = stopResponse.arrivalDriverAppPoint;
      if (stopResponse.arrivalDriverAppPoint !== undefined)
        stop.arrivalDriverAppPoint = stopResponse.arrivalDriverAppPoint;
      if (stopResponse.arrivalTruckPoint !== undefined)
        stop.arrivalTruckPoint = stopResponse.arrivalTruckPoint;
      if (stopResponse.reservations !== undefined)
        stop.reservations = stopResponse.reservations;
      if (stopResponse.acceptanceDriverAppPoint !== undefined)
        stop.acceptanceDriverAppPoint = stopResponse.acceptanceDriverAppPoint;
      if (stopResponse.acceptanceTruckPoint !== undefined)
        stop.acceptanceTruckPoint = stopResponse.acceptanceTruckPoint;
      if (stopResponse.acceptanceSignatureImage !== undefined)
        stop.acceptanceSignatureImage = stopResponse.acceptanceSignatureImage;
      if (stopResponse.acceptanceName !== undefined)
        stop.acceptanceName = stopResponse.acceptanceName;
      if (stopResponse.acceptanceTaxId !== undefined)
        stop.acceptanceTaxId = stopResponse.acceptanceTaxId;
      if (stopResponse.acceptanceAcceptedAt !== undefined)
        stop.acceptanceAcceptedAt = stopResponse.acceptanceAcceptedAt;
      if (stopResponse.sealNumber !== undefined)
        stop.sealNumber = stopResponse.sealNumber;
      if (stopResponse.arrivedAt !== undefined) {
        if (stopResponse.departedAt !== undefined) {
          stop.status = EStopStatus.FINISHED;
        } else {
          stop.status = EStopStatus.STARTED;
        }
      }
      freight.stops.push(stop);
    });

    if (freightDetailedResponse.status !== undefined)
      freight.status = this.transformToEFreightStatus(
        freightDetailedResponse.status
      );
    if (freightDetailedResponse.instructions !== undefined)
      freight.instructions = freightDetailedResponse.instructions;
    if (freightDetailedResponse.carrierObservations !== undefined)
      freight.carrierObservations = freightDetailedResponse.carrierObservations;

    if (
      Array.isArray(freightDetailedResponse.transportInfoPayload?.vehicleTypes)
    ) {
      freightDetailedResponse.transportInfoPayload.vehicleTypes.forEach(
        (vehicle) => {
          freight.vehicleTypes.push(vehicle as unknown as EFreightVehicleType);
        }
      );
    }

    if (
      Array.isArray(freightDetailedResponse.transportInfoPayload?.loadingTypes)
    ) {
      freightDetailedResponse.transportInfoPayload.loadingTypes.forEach(
        (vehicle) => {
          freight.loadingTypes.push(vehicle as unknown as EFreightLoadingType);
        }
      );
    }

    // TODO review this, how to get the price
    if (
      Array.isArray(freightDetailedResponse.freightAssignationResponses) &&
      freightDetailedResponse.freightAssignationResponses.length > 0 &&
      freightDetailedResponse.freightAssignationResponses[0].finalPrice
    ) {
      freight.price =
        freightDetailedResponse.freightAssignationResponses[0].finalPrice;
    }

    if (
      (freightDetailedResponse.numberOfDeliveryNotes === undefined ||
        freightDetailedResponse.numberOfDeliveryNotes === 0) &&
      freight.status !== EFreightStatus.CANCELLED
    ) {
      freight.hasEnoughDocs = false;
    }

    if (Array.isArray(freightDetailedResponse.vehicles)) {
      freight.vehicles = [];
      freightDetailedResponse.vehicles.forEach((vehicleResponse) => {
        const vehicle: IFreightVehicles = {
          id: vehicleResponse.id,
          plate: vehicleResponse.plateNumber,
          partyId: vehicleResponse.partyId,
          type: vehicleResponse.vehicleType as unknown as EVehicleType,
        };
        freight.vehicles?.push(vehicle);
      });
    }

    if (freightDetailedResponse.driver !== undefined) {
      freight.drivers = [
        {
          id: freightDetailedResponse.driver.id,
          name: freightDetailedResponse.driver.driverName,
        },
      ];
    }

    if (freight.stops.length > 0) {
      freight.nextStopType = freight.stops.find(
        (stop) => stop.id === freight.nextStopId
      )?.type;
      freight.nextStopStatus = freight.stops.find(
        (stop) => stop.id === freight.nextStopId
      )?.status;
    }

    // TODO review this, get a more descriptive title
    freight.title = 'Carga ' + freight.reference;

    return freight;
  }*/

  private transformToEFreightStatus(freightStatusResponse: FreightStatus) {
    switch (freightStatusResponse) {
      case FreightStatus.OFFER_REJECTED:
      case FreightStatus.PENDING:
        return EFreightStatus.PENDING;

      case FreightStatus.OFFER_REQUESTED: // ??? seen in freights in progress
      case FreightStatus.OFFER_SENT: // ??? seen in freights in progress
      case FreightStatus.ASSIGNED:
      case FreightStatus.ACCEPTED:
        return EFreightStatus.ASSIGNED;

      case FreightStatus.STARTED:
      case FreightStatus.ARRIVED:
      case FreightStatus.WORKING_START:
      case FreightStatus.WORKING_END:
      case FreightStatus.ON_ROUTE:
        return EFreightStatus.ON_ROUTE;

      case FreightStatus.FINISHED:
      case FreightStatus.FINISHED_BY_CARRIER:
      case FreightStatus.FINISHED_BY_SHIPPER:
      case FreightStatus.FINISHED_WITH_ISSUE:
      case FreightStatus.FINISHED_WITH_ISSUE_SOLVED:
        return EFreightStatus.FINISHED;

      case FreightStatus.CANCELLED_BY_CARRIER:
      case FreightStatus.CANCELLED_BY_SHIPPER:
        return EFreightStatus.CANCELLED;

      case FreightStatus.REJECTED:
      case FreightStatus.NO_SHOW_BY_CARRIER:
      case FreightStatus.NO_SHOW_BY_SHIPPER:
      case FreightStatus.DELETED:
      default:
        return EFreightStatus.DELETED;
    }
  }
}
